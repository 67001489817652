@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/*
  background: -webkit-linear-gradient( #f5c782 20%,#c18d41);
  background-clip: text;
  -webkit-text-fill-color: transparent;

*/
body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

h1,
h3,
h2,
h4,
ul,
ol,
p,
h5,
a,
h5,
h6,
input,
input::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
}

a {
  text-decoration: none !important;
}

.alert.alert {
  /* position: absolute; */
  width: 100%;
  height: 30px;
  padding: 0px;
  border-radius: 0;
  border: 0 !important;
  text-align: center;
  background-color: #EF3F40;
}

.alert-heading {
  color: #fff;
  font-size: 16px;
  padding: 5px 15px;

}

.navgroup a.active {
  background-color: #EF3F40;
  border-radius: 8px;
  color: #fff;
}

.alert-heading svg {
  font-size: 24px;
  padding-bottom: 4px;
}

.alert-dismissible .btn-close {
  padding: 5px 15px;
}

.navbar {
  width: 100%;
  position: absolute;
  z-index: 88;
  top: 0px;
  text-align: center;
  padding: 5px 10px 10px 10px;
  background-color: #fff !important;

}

.navbar .divLeft {
  margin-left: 90px;
  font-weight: 600;

}

.navbar .urlphoto {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.offcanvas {
  background-color: #fff !important;
}

.ImgNav {
  position: relative;
}

.logoImg {
  width: 150px;
  object-fit: contain;
  padding-top: 2px;

}

.offcanlink {
  position: relative;
}

.navgroup a,
.offcanvas-body a {
  color: #000000;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  transition: all .3s ease;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  /* padding-top: 30px; */
  line-height: 16px;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
}



.offcanlink span {
  color: #000;
  font-weight: 600;

}

.navbar a:hover {
  color: #000 !important;
}

.offcanvas-body img {
  width: 50px;
}

.offcanvas {
  background-color: #fff;
}

.offcanvas-header .btn-close {
  color: white !important;
  border: 2px solid white !important;
  position: relative;
  font-weight: 600;

  background: none !important;
}

.offcanvas-header .btn-close::after {
  content: "X";
  position: absolute;
  color: #000 !important;
  top: 0;
  left: 30%;
  font-size: 20px;
}

.navbar-brand {
  padding: 5px !important;
}

.textttt {
  margin-left: auto !important;
  width: 90%;
}

.signbtn {
  font-weight: 600;

  border: 0;
  outline: none;
  padding: 10px;
  border-radius: 12px;
  line-height: 15px;
  background-color: #EF3F40;
  color: #fff;
  font-weight: 600;
}

.pricelink.active,.noactive.active{
  background-color: transparent !important;
}
/*************************************/

.homeFirst {
  background-image: url(background.png);
  min-height: 100vh;
  background-size: cover;
  background-position: center;

}

.homeFirst .col-md-6 {
  position: relative;
  min-height: 90vh;

}

.firstDiv {
  position: absolute;
  min-height: 60vh;
  border: 20px solid #fff;
  padding: 50px;
  width: 140%;
  right: -55%;
  top: 25%;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.firstDiv h1 {
  font-size: 60px;
  font-weight: 700;

}

.firstDiv p {
  font-size: 40px;
  line-height: 42px;
}

.firstDiv button {
  border: none;
  outline: none;
  width: 180px;
  margin: 10px auto 5px;
  font-size: 22px;
  font-weight: 700;
  color: #EF3F40;
  background-color: #fff;
  line-height: 52px;
  border: 1px solid #EF3F40;
}

/*************************************/
.homeSecond {
  text-align: center;
}

.homeSecond p {
  font-size: 28px;
  color: #000;
  font-weight: 500;
  padding: 20px 0;
  margin: 50px 0;
}

.homeSecond p span {
  color: #EF3F40;
}

/*************************************/
.homeThird h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 900;
}

.homeThird .col-md-4 div {
  background-color: #EF3F40;
  min-height: 380px;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
}

.homeThird .col-md-4 div h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
}

.homeThird .col-md-4 div img {
  width: 100px;
  margin: 20px auto;
}

.homeThird .col-md-4 div p {
  color: #fff;
  font-size: 22px;
  font-weight: 600
}

.homeThird .col-md-4 div button {
  border: none;
  outline: none;
  background-color: #000;
  padding: 10px 20px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0;
}

/*************************************/
.homeFourth {
  margin-top: 60px;
}

.homeFourth h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-weight: 900;
  font-size: 45px;
  margin-top: 90px;
}

.homeFourth {
  text-align: center;
}

.homeFourth h3 {
  font-size: 40px;
  font-weight: 900;
  text-align: left;
  margin-bottom: 5px;
}

.homeFourth p {
  color: #000;
  text-align: left;
  padding-bottom: 3px;
  /* margin-left: 100px; */
  font-size: 21px;

}

.homeFourth .rightfourth {
  margin-left: 100px;

}

.homeFourth p span {
  color: #EF3F40;
}

.homeFourth button {
  border: none;
  outline: none;
  background-color: #000;
  padding: 10px 20px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0;
}

.homeFourth img {
  width: 100%;
}

/*************************************/
.formSection img {
  /* height: 60vh; */
  margin-bottom: 30px;
}

.formSection button {
  width: 100%;
  background-color: #EF3F40;
  border-radius: 42px;
  line-height: 42px;

}

.formDiv {
  margin-left: 80px;
  margin-right: 80px;
}

.formDiv input,
.formDiv textarea {
  background-color: #EDECED;
  color: #9E9DA1;
  margin-bottom: 30px;
  border-radius: 0px;
  padding: 20px;
  border: none;
  outline: none;
  box-shadow: none;
}

.react-tel-input {
  margin-bottom: 30px !important;

}

.react-tel-input .form-control {
  background-color: #EDECED !important;
  color: #9E9DA1 !important;
  border-radius: 0px !important;
  padding: 0px 48px !important;
  border: none !important;
  height: 60px !important;
  width: 100% !important;
  outline: none !important;
  box-shadow: none;
}

.inquiry .react-tel-input .form-control {
  background-color: #D8D8D8 !important;
  color: #9E9DA1 !important;
  margin-bottom: 30px;
  border-radius: 12px !important;
  border: none;
  height: 40px !important;

  outline: none;
  box-shadow: none;
}

.thanksInquiry {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.thanksInquiry h3 {
  text-align: center;
  font-size: 28px;
}

.formDiv input:focus,
.formDiv textarea:focus {
  background-color: #EDECED;
  color: #9E9DA1;
  box-shadow: none !important;
}

/*************************************/
.account {
  margin-top: 120px;
  min-height: 100vh;
}

.account img {
  object-fit: contain;
  height: 110vh !important;
  object-position: right;
  margin-right: 20px;
}

.account .formDiv {
  margin-right: 0px;
  margin-left: 150px;

}

.account .checkbox {
  display: flex;
}

.account .checkbox input {
  padding: 10px;
  margin-top: 8px;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.account .haveAccount {
  display: flex;
}

.account a {
  color: #EF3F40;
  text-align: left;
  /* margin-left: 100px; */
  font-size: 22px;
  text-decoration: none;
  margin-left: 5px;

}

.account img {
  height: 100vh !important;

}

.account .haveAccount {
  text-align: center;
}

.account .border {
  margin-top: -20px;
  margin-bottom: 10px;
}

.signin h2 {
  margin-top: 60px;
}

.signin.account .haveAccount {
  justify-content: center;
}

.signin .haveAccount.forget {
  justify-content: flex-end;
}

/*************************************/

footer {
  margin-top: 80px;

  padding: 0px 0px 0;
  min-height: 50vh;
  position: relative;
  background-color: #EF3F40;
}

footer .row {
  padding: 0 150px;
  padding-right: 50px;
}

footer img {
  width: 100px;
  text-align: right;
  object-fit: contain;
  margin-top: 60px;
}

footer .servicesFooter {
  padding-left: 35px;
  padding-top: 55px;

}

footer .emailFooter {
  padding-left: 35px;
  padding-top: 55px;
}

footer .callFooter {
  padding-left: 5px;
  padding-top: 55px;
}

footer h5 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  font-weight: 1000;
}

footer p {
  color: #fff;
  text-align: justify;
  font-weight: 400;
}

footer a {
  list-style: none;
  color: #fff;
  margin-top: 0px;
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3em;
}

footer a:hover {
  color: #fff;
}

footer .iconsfooter {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 50%;
  margin-top: -20px;
  padding-bottom: 20px;
}

footer .iconsfooter img {
  width: 45px;
}

footer .copyRight {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 15px;
  border-top: 1px solid #fff;

}

footer .footerBorder {
  padding: 0 70px;
  margin-top: 50px;
}

footer .copyRight a {
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  font-weight: 600;

}


.imgmob img {
  object-position: bottom;
  object-fit: cover;
  height: 400px;
}


.buildva .container {
  padding: 0 100px;
}

.inquiry input {
  height: 21px;
  background-color: #D8D8D8;
  border-radius: 12px;
}

.inquiry label {
  text-align: left;
}

.inquiry.account .formDiv {
  margin-left: 0;
}

.homeFourth.inquiry {
  text-align: unset;
}

.homeFourth.inquiry .custom-button {
  width: 100%;
  padding: 15px 17px;
  border: 2px solid #E4E5E836 !important;
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  font-size: 14px;
  color: #898989;
  transition: all 0.2s ease-out;
  background-color: #D8D8D8;
  border-radius: 2%;
  color: #6e7d84 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeFourth.inquiry p {
  font-size: 12px;
}

.inquiry .checkbox {
  margin-top: 20px;
}

.inquiry .submitDiv {
  text-align: center;
}

.inquiry.formSection button {

  width: 300px;
  margin: auto;
}

.buildva {
  /* margin-top: 90px; */
  margin-bottom: 30px;
}

.homeFourth.buildva h3 {
  font-size: 42px;
  text-align: center;
  font-weight: 800;
  margin-top: 10px;
}

.homeFourth.buildva h4 {
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
}

.homeFourth.buildva .rightfourth {
  margin-left: 0px;
  margin-right: 40px;

}

.homeFourth.buildva .container {
  padding: 0 100px;
}

.homeFourth.buildva p {
  margin-top: 5px;
  font-size: 20px;
}

.homeSecond.buildva h2 {
  font-size: 42px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 20px;
}

.homeSecond.buildva h2 span {
  color: #EF3F40;
}

.homeSecond.buildva p {
  font-size: 22px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 35px;
  padding: 10px 15px;

}

.homeSecond.buildva button {
  border: none;
  outline: none;
  width: 450px;
  margin: 15px auto 5px;
  font-size: 22px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 52px;
  line-height: 52px;

}

/******************************/
.blogsSec1 {
  margin-top: 100px;
}

.blogsSec1 h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 900;

}

.blogsSec1 {
  text-align: center;
}

.blogsSec1 h3 {
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  padding-top: 50px;
}

.blogsSec1 h3 span {
  color: #EF3F40;
}

.blogsSec1 img {
  height: 350px;
  object-fit: contain;
  margin-top: 10px;
}

.blogsec2 h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-size: 42px;
  margin-top: 20px;
  font-weight: 900;
}

.blogsec2 .accordion-item {
  border: none !important;
}

.blogsec2 .accordion-button {
  background: none;
  font-size: 36px;
  color: #000;
  font-weight: 700;
}

.blogsec2 .accordion {
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
  padding: 10px 0;
}

.blogsec2 .accordion-button:focus {
  box-shadow: none !important;
}

.blogsec2 .accordion-body {
  font-size: 22px;
}

.blogsec2 .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.blogsec3 h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-size: 50px;
  margin-top: 45px;
  font-weight: 900;

}

.blogsec3 ul li {
  list-style: none;
  margin-bottom: 10px;
  font-size: 28px;
}

.blogsec3 ul li svg {
  color: #EF3F40;
  margin-right: 10px;
  font-size: 40px;
  margin-top: -10px;
}

.blogsec3 p {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin: 35px 0;
}

/********************/

.pricingSec1 {
  margin-top: 100px;
}

.pricingSec1 h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 900;
}

.pricingSec1 h2 span {
  font-weight: 900;

}

.pricingSec1 .container,
.pricingSec2 .container,
.pricingSec3 .container,
.pricingSec4 .container {
  padding: 0 100px;
}

.pricingSec1 h3 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: left;
}

.pricingSec1 p {
  font-weight: 300;
  font-size: 22px;
}

.pricingSec1 p span {
  color: #EF3F40;
}

.pricingSec2 h2 {
  color: #EF3F40;
  text-align: center;
  padding-bottom: 0px;
  font-size: 42px;
  font-weight: 900;
  margin-top: 30px;

}

.pricingSec2 h4 {
  font-size: 35px;
  font-weight: 900;
  /* margin-bottom: 30px; */
  text-align: center;
}

.pricingSec2 .col-md-4 {
  padding: 20px;
}

.pricingSec2 .col-md-4 div {
  text-align: center;
  background-color: #DDDDDD;
  border-radius: 20px;
  padding: 30px;
}

.pricingSec2 .col-md-4 div h3 {
  font-size: 22px;
  font-weight: 800;
}

.pricingSec2 .col-md-4 div h3 span {
  font-size: 18px;
}

.pricingSec2 .col-md-4 div p {
  font-size: 22px;
  font-weight: 600;
}

.pricingSec2 .col-md-4 div p span {
  color: #EF3F40;
  font-weight: 700;
}

.pricingSec2 .col-md-4 div.differ {
  background-color: #EF3F40;

}

.pricingSec2 .col-md-4 div.differ h3,
.pricingSec2 .col-md-4 div.differ span {
  color: #FFFFFF;
}

.pricingSec2 {
  text-align: center;
}

.pricingSec2 button {
  border: none;
  outline: none;
  width: 450px;
  font-size: 22px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 52px;
  line-height: 52px;
  margin: 50px auto;
}

.pricingSec3 .container {
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
  padding: 30px 0;
}

.pricingSec3 h2 {
  color: #000;
  text-align: center;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 900;
  margin-top: 30px;
}

.pricingSec3 .col-md-4 div {
  text-align: center;
  background-color: #DDDDDD;
  border-radius: 20px;
  padding: 30px;
}

.pricingSec3 .col-md-4 div h3 {
  font-size: 30px;
  font-weight: 800;
}

.pricingSec3 .col-md-4 div h3 span {
  font-size: 20px;
}

.pricingSec3 .col-md-4 div p {
  font-size: 30px;
  font-weight: 800;
  color: #EF3F40;
}

.pricingSec3 .col-md-4 div p span {
  font-size: 22px;

}

.pricingSec3 .col-md-4 button {
  border: none;
  outline: none;
  width: 200px;
  font-size: 22px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 40px;
  line-height: 40px;
  margin: auto;
}

.pricingSec3 p {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin: 35px 0;
}

.pricingSec3 h5 {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  margin-left: 30px;
}


.pricingSec4 h2 {
  color: #000;
  text-align: center;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 900;
  margin-top: 30px;
}

.pricingSec4 h4 {
  color: #000;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0px;
}

.pricingSec4 h3 {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-top: 30px;
}

.pricingSec4 p {
  text-align: center;
  font-size: 20px;

}

.pricingSec4 button {
  border: none;
  outline: none;
  width: 300px;
  font-size: 26px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 48px;
  line-height: 48px;
  margin: 40px auto;
}

.comp {
  text-align: center;
}

.pricingSec4 input {
  outline: none;
  border: none;
  background-color: #D8D8D8;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
}

.yearly {
  position: relative;
}

.yearly img {
  position: absolute;
  right: -40px;
  width: 100px;
  top: -30px;
}

.about {
  margin-top: 100px;
}



.about img {
  margin-top: 30px;
}

.accountEdit {
  margin-top: 100px;
}

.accountEdit ul li {
  list-style: none;
  margin-top: 15px;
  font-size: 22px;

}

.accountEdit ul li svg {
  color: #EF3F40;
}

.accountEdit h2 {
  color: #000;
  font-weight: 700;
}

.inquiry.formSection.accountEdit button {
  font-size: 18px;
  line-height: 22px;
  border-radius: 16px;
  width: fit-content;
  margin-bottom: 30px;
}

.accountEdit label {
  font-size: 24px;
  margin-bottom: 20px;
}

.accountEdit p {
  font-size: 22px !important;
  color: #EF3F40;
}

.accountEdit .row {
  align-items: last baseline;
}

.accountEdit input {
  padding: 26px;
}

.accountEdit img {
  width: 200px;
}

.imggg img {
  height: 200px;
  border-radius: 50%;
}

.imageFile.upload img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #EF3F40;
  object-fit: contain;
}

.listUpload {
  margin-top: 100px;
}

.list {
  display: flex;
  align-items: center;
}

.list img {
  width: 40px;
  margin-right: 8px;
}

.list h3 {
  color: #EF3F40;
  font-size: 32px;
  font-weight: 900;
}

.uploaddiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.uploadInput {
  border: none;
  outline: none;
  width: 150px;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
}

.uploadInput svg {
  margin-right: 5px;
}

.uploaddiv p {

  font-size: 12px;
}

.uploadDiv img {
  height: 200px;
  object-fit: contain;
  object-position: center;
  margin-top: 40px;
}

.uploadDiv {
  text-align: center;
  color: #D8D8D8;

}

.uploadDiv h3 {
  margin-top: 20px;
  font-size: 56px;
  font-weight: 400;
}

.uploadDiv p {
  font-size: 24px;

}

.listIns {
  margin-top: 20px;
}

.listIns h3 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.listIns li {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.listIns li span {
  color: #EF3F40;
}

.listIns p {
  width: 75%;
  margin: 20px auto;
  font-weight: 600;

  font-size: 22px;
  text-align: center;

}

.file-name {
  font-size: 18px;
}

.listIns .custom-button,
.listbtn button {
  border: none;
  outline: none;
  width: 250px;
  font-size: 22px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 40px;
  line-height: 40px;
  margin: 40px auto;
}

.my-confirm-button {
  background-color: #EF3F40 !important;
  /* Green */
  color: white;
  border-radius: 5px;
  /* Optional: Rounded corners */
  padding: 10px 20px;
  /* Optional: Padding */
  font-size: 16px;
  /* Optional: Font size */
}

.my-confirm-button:hover {
  background-color: #ef3f3f6c;
  /* Darker green on hover */
}

table button {
  width: 180px;
  font-size: 16px;

}

.listbtn {
  text-align: center;
}

.tableShowList .table-responsive {
  border: 1px solid #D8D8D8;
}

.tableShowList th {
  font-weight: 400;
  padding-bottom: 20px !important;
}

th,
td {
  min-width: 200px;
  vertical-align: middle;

}

.tableShowList table {
  margin: 0 !important;
}

.tableShowList tbody,
td,
tfoot,
th,
thead,
tr {
  /* border-style: none; */
  text-align: center;
  padding: 4px !important;
  font-weight: 500;
  font-size: 14px;
}

.tableShowList th {
  font-weight: 600;
}

/* .tableShowList button{
  margin: 2px  !important;
  line-height: 28px;
  width: 150px;
} */
.categoryList .head {
  display: flex;
  background-color: #D8D8D8;
  padding: 5px;

}

.categoryList {
  border: 1px solid #D8D8D8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-bottom: 10px;
}

.categoryl {
  border: 2px solid #D8D8D8;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.categoryl p,
.categoryl h5 {
  font-size: 15px;

}

.categoryl h5 {
  font-weight: 800;
}

.categoryList .head {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.categoryList div.head p {
  flex-basis: 50%;
}

.categoryList .d-flex {
  margin-top: 20px;
  padding: 5px;
}

.categoryList label {
  font-size: 14px;
  flex-basis: 50%;

}

.categoryList select {
  background-color: #D8D8D8;
  width: 130px;
  border: none;
  outline: none;
  border-radius: 12px;
  font-size: 16px;
}

.page-link {
  background-color: transparent !important;
  border: none !important;
  padding: 8px;
  color: #25213B !important;
  font-size: 18px;
  /* margin-top: 20px; */
}

.tableShowList .pagination {
  margin-top: 30px;
}

/* .page-item {
  margin: 20px 30px;

} */

/* .page-item.active {
  background-color: #EF3F40;
} */

.page-item.active .page-link {
  color: #EF3F40 !important;

}

.loader {
  width: 30px;
  padding: 5px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.buttonDownload {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 8px;
  line-height: 22px;

}

.buttonDownload:focus {
  background-color: #ef3f3f8a;

}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.searchDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

.searchDiv input {
  padding: 10px;

}

.tableShowList table th {
  font-family: "Inter", sans-serif;
  /* font-size: 20px !important; */
}

.tableShowList table td {
  padding: 10px 0 !important;
}

.tableShowList thead th {
  background-color: #EF3F40 !important;
  color: #fff;
  vertical-align: middle;
  padding: 10px 0 !important;
}

.nameTd {
  display: flex;
  /* justify-content: space-between; */
}

.nameTd p {
  margin-left: 20px;
  flex-basis: 90%;
}

.searchDiv input::placeholder {
  color: #9E9DA1;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #9E9DA1 !important;
}

.searchInput {
  position: relative;
  width: 63%;

}

.searchInput svg {
  position: absolute;
  color: #9E9DA1;
  font-size: 24px;
  top: 12px;
  left: 10px;
}

.dropicon {
  color: #EF3F40;
  font-size: 18px;
  flex-basis: 10%;
  margin-left: 10px;
}

.searchDiv input[type="text"] {
  padding-left: 35px;

}

.searchDiv input[type="date"] {
  width: 33%;
  color: #9E9DA1;

}

.searchDiv input:focus {

  box-shadow: none;
  border: 1px solid #EF3F40;
}

.downloadBtn {
  background-color: transparent;
  border: none;
  color: #25213B;
  font-size: 20px;
}

.differ td {
  background-color: #ececec !important;
  font-size: 12px !important;
  color: #898989;

}

.selectedTr td {
  background-color: #F4F2FF;
}

.paginationTable {
  align-items: baseline;
  /* text-align: center; */
  margin-top: 30px;
  color: #666666;
  font-size: 18px;

}

.paginationTable a {
  color: #EF3F40;
}

.spanStatus {
  padding: 2px 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 110px;
  justify-content: center;
  margin: auto;
}

.spanStatus.Processing {
  background-color: #FCDCBD;
  color: #EB7500;
}

.spanStatus.Progress,
.spanStatus.progress {
  background-color: #E6E6F2;
  color: #5B528E;
}

.spanStatus.Optimizing {
  background-color: #C9E3FC;
  color: #324558;
}

.spanStatus.Completed {
  background-color: #CCFFC8;
  color: #127909;
}
.spanStatus.Refunded {
  background-color: #e0e0e0; 
  color: #6c757d;          
}
.spanStatus.Queue {
  background-color: #e6e6fa; 
  color: #4b0082;          
}
.spanStatus.Cancelled {
  background: #ffd6d6;
  color: red;
}
.spanStatus.Uploading {
  background-color: #FFF3CD;
  color: #856404; 
}

.spanStatus.Received {
  background-color: #C8E6C9; 
  color: #388E3C;
}


.progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.progress-text {
  font-size: 0.9em;
  color: #333;
}

.progress-bar {
  position: relative;
  width: 100px;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}



.progress-fill {
  height: 100%;
  background-color: rgb(204, 254, 221);
  transition: width 0.3s ease-in-out;

  border-radius: 5px;
}

/*****************************************************************************/
@media only screen and (max-height: 645px) {
  .homeSec1 {
    min-height: 100vh;
    overflow: hidden;

  }

  .homeSec1 img {
    position: absolute;
    object-fit: contain;
    position: absolute;
    width: 180%;
    top: 16%;
    left: -60%;
    object-fit: contain;
  }

}



@media only screen and (max-width: 1024px) {


  .navbar {
    padding: 12px 10px 30px 10px;
  }

  .homeSec1 {
    padding-left: 30px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  footer {
    padding: 40px 20px 0px;

  }

  .navbar {
    border: 1px solid rgba(255, 255, 255, 0.321);
  }


  footer .row {
    padding: 0 10px;
    padding-right: 10px;
  }

  footer .servicesFooter {
    padding-left: 5px;
    padding-top: 55px;

  }

  footer .emailFooter {
    padding-left: 5px;
    padding-top: 55px;
  }

  footer .callFooter {
    padding-left: 5px;
    padding-top: 55px;
  }

  .pricingSec1 .container {
    padding: 0 20px;
  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #EF3F40;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;
    font-size: 12px;

  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #EF3F40;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;
    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #EF3F40;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }

  .serviceDetail .row {
    padding: 0;
  }

  .about img {
    margin-bottom: 30px;
  }

  .textttt {
    width: unset;
  }

  .homeFirst .col-md-6 {
    width: 60%;
  }

  .firstDiv {
    width: unset;
  }

  .list h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .homeFirst .col-md-6 {
    width: 100%;
  }

  .listUpload .px-5 {
    padding: 0 !important;
  }

  .homeFirst {
    min-height: 80vh;
  }

  .listIns p {
    width: unset;
  }

  .buildva .container {
    padding: 0 10px;
  }

  .homeFourth.buildva .container {
    padding: 0 10px;
  }

  th,
  td {
    min-width: 120px;
  }

  .homeFirst .col-md-6 {
    min-height: 70vh;

  }

  .homeFourth .rightfourth {
    margin-left: 0px;
  }

  .firstDiv {

    min-height: 50vh;
    border: 10px solid #fff;
    padding: 10px;
    width: 90%;
    right: 5%;
    top: 25%;
  }

  .firstDiv h1 {
    font-size: 30px;
    font-weight: 700;

  }

  .firstDiv p {
    font-size: 20px;
    line-height: 22px;
  }

  .nameTd {
    display: block !important;
  }

  .nameTd svg {
    opacity: 0;
  }

  .paginationTable p {
    font-size: 14px;
  }

  /*************************************/

  .homeSecond p {
    font-size: 18px;

  }

  .navbar .divLeft {
    margin-left: 0px;
  }

  footer a {
    font-size: 14px;
  }

  .formDiv {
    margin-left: 0px;
    margin-right: 0px;
  }

  .textttt {
    width: unset;
  }

  .navbar-toggler.collapsed {
    border: 0 !important;
  }

  .homeFourth h3 {
    font-size: 24px;
  }

  .homeThird h2,
  .homeFourth h2 {
    font-size: 36px;

    margin-top: 110px;
  }

  .homeFourth p {
    margin: 0;
    font-size: 16px;
  }

  .navbar {
    padding: 28px 0px 15px 9px;

    top: 0 !important;
    left: 0 !important;
  }

  .thanksInquiry {
    margin-top: 40px;
  }

  .logoImg {
    width: 100px;
  }

  .offcanvas.show {
    width: 70%;
  }

  .offcanvas-body a {
    border: none !important;
  }

  .imgmob img {
    height: 300px;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }

  .account .formDiv {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .homeFourth p {
    font-size: 13px;

  }

  .pricingSec1 .container,
  .pricingSec2 .container,
  .pricingSec3 .container,
  .pricingSec4 .container {
    padding: 0 10px;
  }

  .formDiv input,
  .formDiv textarea {
    padding: 10px;
  }

  .formSection button {
    line-height: 22px;
    border-radius: 22px;
  }

  .account img {
    height: 50vh !important;
    object-position: center;
  }

  .navgroup .align-items-center {
    flex-direction: column;
  }

  .account a {
    font-size: 13px;
    margin-left: 0px;
  }

  footer {
    padding-top: 0;
    text-align: center;
    padding: 0 !important;
    padding-bottom: 0 !important;
  }

  footer img {
    margin-top: 30px;
  }

  footer p {
    font-weight: 300;
    font-size: 14px;
  }

  footer .copyRight,
  .copyRight div {
    flex-direction: column !important;
  }

  footer .footerBorder {
    padding: 0;
  }

  footer .footerBorder p {
    text-align: center;

  }

  .servicesFooter,
  footer .emailFooter,
  footer .callFooter {
    padding-top: 0 !important;
    margin-top: 10px !important;
  }

  footer .iconsfooter {
    justify-content: space-around;
    width: 80%;
    margin-top: 30px;
  }

  footer .emailFooter h5 {
    margin-top: 20px;
  }

  footer .emailFooter p {
    text-align: center;
  }

  footer .emailFooter p:nth-child(3) {
    margin-top: 30px !important;
  }

  .homeFourth.buildva h3,
  .homeFourth.buildva h4,
  .homeSecond.buildva h2 {
    font-size: 28px;
  }

  .homeSecond.buildva p {
    margin-left: 0px;
  }

  .homeFourth.buildva p,
  .homeSecond.buildva p {
    font-size: 18px;
  }

  .homeFourth.buildva .rightfourth {
    margin-right: 0px;
  }

  .buildva,
  .homeFourth.buildva p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .homeSecond.buildva p {
    padding: 5px;
    margin-bottom: 5px;
  }

  .homeSecond.buildva button {
    width: 280px;
    font-size: 18px;
  }

  .pricingSec1 h2,
  .pricingSec2 h2,
  .pricingSec2 h4,
  .pricingSec3 h2 {
    font-size: 36px;
    margin-bottom: 0;
  }

  .pricingSec1 h3 {
    font-size: 36px;

  }

  .blogsSec1 img {
    height: auto;
  }

  .pricingSec4 h3 {
    font-size: 28px;
  }

  .pricingSec1 p {
    font-size: 18px;
  }

  .pricingSec2 button {
    width: 280px;
  }

  .pricingSec3 .col-md-4 div {
    margin: 10px;
  }

  .blogsSec1 h3 {
    font-size: 40px;
    padding-top: 10px;
  }

  .blogsec2 h2,
  .blogsec2 .accordion-button,
  .blogsec3 h2 {
    font-size: 32px;
  }

  .blogsec2 .accordion-body,
  .blogsec3 ul li,
  .blogsec3 ul li svg,
  .blogsec3 p {
    font-size: 18px;
  }

  .inquiry input {
    height: 40px;
  }

  .imageFile.upload img {
    width: 200px;
    height: 200px;

  }

  .imageFile img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  ul {
    padding: 0;
  }

  .blogsec3 ul li svg {
    margin-right: 5px;

  }

  .yearly img {
    top: 0;
    right: 0px;
  }
}



@media screen and (max-width: 290px) {

  .homeSec1 div h1 {
    font-size: 30px !important;
    margin-top: 10px !important;
  }

  .navbar-toggler.collapsed {
    color: white !important;
    margin-left: 90px;
  }



  .navbar.fixed .navbar-toggler.collapsed {
    margin-left: 90px;
  }

  .homeSec1 p {
    line-height: 20px !important;
  }



  .navbar.fixed .navbar-toggler.collapsed {
    margin-left: 80px !important;
  }




}

.couponform {
  display: flex;
  height: 30px;
  align-items: center;
}


.pricingSec4 .couponform button {
  border: none;
  outline: none;
  width: 250px;
  padding: 0px;
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  background-color: #EF3F40;
  border-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  line-height: 40px;
  margin: unset;
}

.couponform input {
  height: 40px;
  padding-left: 10px;
  border-radius: unset;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}